import { Outlet } from 'react-router-dom';

import { withSuspense } from 'src/hoc';
import { BackupContextProvider, useBackup } from 'src/context/backup';
import { JourneyLayoutSkeleton } from 'src/components/Journey/Layout';
import { BackupRemoveContainer, BackupLayoutContainer } from 'src/containers/Backup';

const RemoveBackup = () => {
  const { backup, ui, setRemoveOpen } = useBackup();

  const closeModal = () => setRemoveOpen(false);

  return <BackupRemoveContainer isOpen={ui.isRemoveOpen} backupId={backup.id} closeModal={closeModal} />;
};

const BackupSlugLayout = () => {
  return (
    <div className="d-flex h-100vh flex-column">
      <BackupContextProvider>
        <BackupLayoutContainer>
          <Outlet />
          <RemoveBackup />
        </BackupLayoutContainer>
      </BackupContextProvider>
    </div>
  );
};

const FallBack = () => <JourneyLayoutSkeleton indicatorItems={3} menuItems={5} />;

export default withSuspense(BackupSlugLayout, FallBack);
