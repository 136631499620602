import { Outlet } from 'react-router-dom';
import { useBackgroundQuery } from '@apollo/client';
import { Fragment } from 'react';

import { BackupsDocument } from 'src/graphql/schema';
import { BackupsContainer } from 'src/containers/Backups';
import { Main } from 'src/components/Layout';

import type { IBackupsQuery } from 'src/graphql/schema';
import type { QueryRef } from '@apollo/client';

import { NewBackupAction } from './ui';

export type IBackupOutletContext = {
  queryRef: QueryRef<IBackupsQuery>;
};

const BackupsPage = () => {
  const [queryRef] = useBackgroundQuery<IBackupsQuery>(BackupsDocument, {
    variables: {
      perPage: 100,
    },
  });

  return (
    <Fragment>
      <Main title="My Backups" headerActions={<NewBackupAction />}>
        <BackupsContainer queryRef={queryRef} />
      </Main>

      <Outlet context={{ queryRef }} />
    </Fragment>
  );
};

export default BackupsPage;
