import { Outlet, createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { ConfigProvider, App as AntdApp } from 'antd';

import { apolloClient } from 'src/graphql';
import { LayoutContainer as Layout } from 'src/containers/Layout';
import env from 'env';
import Graphiql from 'src/components/GraphiQL/GraphiQL';

import { theme } from '../config/antd.config.ts';

import { buildProvidersTree } from './utils/buildProvidersTree';
import {
  authRoutes,
  profileRoutes,
  escrowRoutes,
  invitationsRoutes,
  mainRoutes,
  saasEnvironmentsRoute,
  saasEscrowsRoutes,
  backupsRoutes,
  backupSingleRoutes,
  custodianRoute,
  custodianSingleRoutes,
} from './routes';
import { AuthProvider } from './context/auth/use-auth';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import { NotFound } from './components/404';
import { ErrorBoundary, Message } from './components/Misc';

import './assets/styles/app.scss';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    element: (
      <AuthProvider>
        <Outlet />
      </AuthProvider>
    ),
    children: [
      { ...authRoutes },
      { ...invitationsRoutes },
      // Protected routes with main layout
      {
        element: (
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        ),
        errorElement: <ErrorBoundary />,
        children: [
          { ...mainRoutes },
          { ...profileRoutes },
          { ...escrowRoutes },
          { ...saasEnvironmentsRoute },
          { ...saasEscrowsRoutes },
          { ...backupsRoutes },
          { ...custodianRoute },
        ],
      },
      // Protected routes with custom layout
      {
        element: (
          <ProtectedRoute>
            <Outlet />
          </ProtectedRoute>
        ),
        errorElement: <ErrorBoundary />,
        children: [{ ...custodianSingleRoutes, ...backupSingleRoutes }],
      },
      ...(env.VITE_GRAPHIQL_ENABLED
        ? [
            {
              path: 'graphiql',
              element: <Graphiql />,
            },
          ]
        : []),
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: '404',
    element: <NotFound />,
  },
]);

const AntdWrapper = ({ children }: React.PropsWithChildren) => (
  <AntdApp>
    <Message />
    {children}
  </AntdApp>
);

const ProvidersTree = buildProvidersTree([
  [ApolloProvider, { client: apolloClient }],
  [ConfigProvider, { theme }],
  [AntdWrapper],
  [RouterProvider, { router }],
]);

const App = () => {
  // react-router-dom crashes when there are multiple slashes in the URL
  // This is a workaround to prevent this crash, but it's not a perfect solution
  // TODO: Find a better solution later
  useEffect(() => {
    const { pathname } = window.location;

    if (pathname.match(/\/{2,}/)) window.location.replace(pathname.replace(/\/{2,}/g, '/'));
  }, []);

  return <ProvidersTree />;
};

export default App;
