import { Table, Form, Input, Button } from 'antd';
import { useState } from 'react';
import { uniqueId } from 'lodash';

import { DepositAddIntegrationLayout } from 'src/components/Deposit/Add';
import { TextBlock, message } from 'src/components/Misc';
import { IconBin } from 'src/components/Svg/Icons';
import { CustomIntegrationProviderEnum } from 'src/graphql/schema';
import { useAddDeposit } from 'src/context/deposit';

interface ITableRow {
  key: string;
  title: string;
  url: string;
  isEditable?: boolean;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
}

const EditableCell = ({ editing, dataIndex, title, children, ...restProps }: EditableCellProps) => (
  <td {...restProps}>
    {editing ? (
      <Form.Item
        name={dataIndex}
        style={{ margin: 0 }}
        rules={[
          {
            required: true,
            message: `Please add ${title}.`,
          },
        ]}
      >
        <Input placeholder={title} />
      </Form.Item>
    ) : (
      children
    )}
  </td>
);

const CustomDepositContainer = () => {
  const [form] = Form.useForm();
  const {
    ui: { integration, customData, setCustomData, getBack, history },
  } = useAddDeposit();
  const [dataSource, setDataSource] = useState<ITableRow[]>([
    {
      key: '0',
      title: '',
      url: '',
      isEditable: true,
    },
    ...customData.map((item) => ({
      key: uniqueId(),
      title: item.title || '',
      url: item.url,
      isEditable: false,
    })),
  ]);

  let columns: {
    title: string;
    dataIndex: string;
    width: string;
    editable?: boolean;
    render?: (text: string, record: ITableRow) => JSX.Element;
  }[] = [
    {
      title: ' ',
      dataIndex: 'operation',
      width: '20%',
      render: (_: unknown, record: ITableRow) => {
        const handleRemove = () => onRemove(record.key);

        return record.isEditable ? (
          <Button type="link" onClick={saveAsset}>
            + Add Asset
          </Button>
        ) : (
          <Button danger type="link" onClick={handleRemove}>
            <IconBin />
            Remove
          </Button>
        );
      },
    },
  ];

  const provider = integration?.platform as CustomIntegrationProviderEnum;
  switch (provider) {
    case CustomIntegrationProviderEnum.GitlabServer:
    case CustomIntegrationProviderEnum.BitbucketServer:
    case CustomIntegrationProviderEnum.GithubServer:
    case CustomIntegrationProviderEnum.Beanstalk:
      columns = [
        {
          title: 'Repository Title',
          dataIndex: 'title',
          width: '40%',
          editable: true,
        },
        {
          title: 'Repository URL',
          dataIndex: 'url',
          width: '40%',
          editable: true,
        },
        ...columns,
      ];
      break;
    case CustomIntegrationProviderEnum.Codecommit:
      columns = [
        {
          title: 'URL',
          dataIndex: 'url',
          width: '80%',
          editable: true,
        },
        ...columns,
      ];
      break;
    case CustomIntegrationProviderEnum.Aws:
      columns = [
        {
          title: 'Bucket Name',
          dataIndex: 'url',
          width: '80%',
          editable: true,
        },
        ...columns,
      ];
      break;
  }

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: ITableRow) => ({
        dataIndex: col.dataIndex,
        title: col.title,
        editing: record.isEditable,
      }),
    };
  });

  const saveAsset = async () => {
    try {
      const row = (await form.validateFields()) as ITableRow;

      setDataSource((dataSource) => [...dataSource, { ...row, key: uniqueId() }]);
      form.resetFields();
    } catch (e) {
      message.error('Please fill in all fields.');
    }
  };

  const onRemove = (key: string) => setDataSource(dataSource.filter((item) => item.key !== key));

  const onConfirm = () => {
    const [, ...rest] = dataSource;
    const assetsInput = rest.map(({ title, url }) => ({
      url,
      ...(title && { title }),
    }));

    setCustomData(assetsInput);
  };

  return (
    <DepositAddIntegrationLayout
      integration={{
        provider: integration?.platform || '',
        account: integration?.name || '',
      }}
      onConfirm={onConfirm}
      confirmButton={{
        disabled: !(dataSource.length - 1),
      }}
      onBack={getBack}
      isBackButtonVisible={history.length > 1}
    >
      <TextBlock
        as="h4"
        titleColor="dark-blue"
        title="Asset Details"
        text="Provide the asset details to be deposited for this integration."
        className="mb-4"
      />
      <Form form={form} component={false}>
        <Table
          className="ck-deposit__assets-table"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowHoverable={false}
          columns={mergedColumns}
          dataSource={dataSource}
          pagination={false}
          scroll={{
            y: 380,
          }}
        />
      </Form>
    </DepositAddIntegrationLayout>
  );
};

export default CustomDepositContainer;
