export const ESCROWS_LIMIT = 10;
export const SAAS_ESCROWS_LIMIT = 10;
export const DEPOSITS_PER_PAGE = 20;
export const ASSETS_LIMIT = 10;
export const LEGACY_REPOS_LIMIT = 10;
export const USER_INTEGRATIONS_LIMIT = 100;
export const UPLOAD_SIZE_LIMIT = Math.pow(2, 30) * 3; // 3GB
export const EXCLUSIVE_OFFER = 40;
export const REPORTS_LIMIT = 20;
export const CUSTODIAN_INTEGRATION_LIMIT = 10;

export const PROVIDER_KEY_TO_NAME = {
  azure: 'Azure',
  bitbucket: 'Bitbucket',
  gcp: 'GCP',
  gitlab: 'Gitlab',
  github: 'Github',
  github_server: 'Github Enterprise',
  gitlab_server: 'Gitlab Private',
  codecommit: 'Amazon Codecommit',
  aws: 'Amazon S3',
  share_file_https: 'Sharefile',
  azure_devops: 'Azure DevOps',
  bitbucket_server: 'Bitbucket Server',
  beanstalk: 'Beanstalk',
};

export const PROVIDERS_WITH_USERNAME = ['gitlab_server', 'github_server', 'bitbucket_server', 'beanstalk'];

export const REPORTS_START_DATE = '2024-06-01';
