import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

import { JourneyFooter } from 'src/components/Journey';
import { useBackup } from 'src/context/backup';
import { BackupStatusEnum } from 'src/graphql/schema';

import StartBackupContainer from './Actions/StartBackupContainer';

const actionItemsKeys = ['start-backup', 'close', 'close-access', 'remove-backup'] as const;

const BackupFooterContainer = () => {
  const navigate = useNavigate();
  const {
    backup: { status, policy },
    ui,
    setEditAccessMode,
    setRemoveOpen,
  } = useBackup();

  let actionItems: { key: (typeof actionItemsKeys)[number]; component: React.ReactNode }[] = [
    {
      key: 'close',
      component: (
        <Button type="default" onClick={() => navigate('/backups')}>
          Close
        </Button>
      ),
    },
  ];

  if (ui.isEditSettingsMode) return null;

  if (ui.isEditAccessMode) {
    const handleCancel = () => setEditAccessMode(false);

    actionItems = [
      {
        key: 'close-access',
        component: <Button onClick={handleCancel}>Close</Button>,
      },
    ];
  }

  if (status === BackupStatusEnum.Draft && !ui.isEditAccessMode)
    actionItems = [
      ...actionItems.filter(({ key }) => key !== 'close'),
      {
        key: 'start-backup',
        component: <StartBackupContainer />,
      },
    ];

  if (policy.destroy) {
    const onRemoveBackup = () => setRemoveOpen(true);

    actionItems = [
      {
        key: 'remove-backup',
        component: (
          <Button danger onClick={onRemoveBackup}>
            Remove Backup
          </Button>
        ),
      },
      ...actionItems,
    ];
  }

  return (
    <JourneyFooter justify={policy.destroy ? 'space-between' : 'end'}>
      {actionItems.map(({ key, component }) => (
        <JourneyFooter.Item key={key}>{component}</JourneyFooter.Item>
      ))}
    </JourneyFooter>
  );
};

export default BackupFooterContainer;
